import documentBind, {stopScroll, startScroll} from './_documentBind';
import windowBind from './_windowBind';

const TRANSITION_END = 'webkitTransitionEnd oTransitionEnd otransitionend transitionend';

export default class OpenNavigation {
    constructor() {
        this.$mask = $('#js-navigation-sp-mask');
        this.$button = $('#js-navigation-button');
        this.$navigation = $('#js-navigation-header');
        // this.$item = this.$navigation.children('.navigation-sp-item');
        this.$item = $('.navigation-sp-item');
        this.$itemClose = $('#js-navigation-sp-close');
        // this.$itemFooter = $('');
        this.itemChildName = '.navigation-sp-child';
        this.$wrapper = $('#js-wrapper');
        this.headerHeight = 50; // SP ヘッダーの高さ
        
        // this.options = options;
        //
        // this.setValBrowserDescriotion();
        //
    }

    // setValBrowserDescriotion() {
    //     this.browserDecision = [];
    //     if (this.options.browserDecision.length !== 0) {
    //         this.browserDecision = this.options.browserDecision;
    //     }
    //     console.log(this.browserDecision);
    // }
    
    bindEvents() {
        this.$button.on('click', ()=>{
            if (this.$button.hasClass('is-open')){
                this.close();
            } else {
                this.open();
            }
        });


        this.$navigation.on(TRANSITION_END,()=>{
            this.setScroll();
        });

        this.$navigation.on('click', (e)=>{
            if (e.currentTarget === e.target) {
                this.close();
            }
        });



        this.$item.on('click', (t)=>{
            this.toggleItem(t);
        });

        this.$itemClose.on('click', ()=>{
            this.close();
        });
    }

    setHeight() {
        this.$navigation.css({
            'min-height': windowBind.height
        });
    }

    close() {
        startScroll();
        this.$mask.removeClass('is-fixed');
        this.$button.removeClass('is-open');
        this.$navigation.removeClass('is-scroll').removeClass('is-open');
        this.$wrapper.removeClass('is-freeze').css({'top':''});
        documentBind.$target.scrollTop(documentBind.position);
    }

    open() {
        stopScroll();
        this.$wrapper.css({'top': -(documentBind.position)});
        this.$button.addClass('is-open');
        this.$navigation.addClass('is-open');
        this.$wrapper.addClass('is-freeze');

    }


    setScroll() {
        if (this.$navigation.hasClass('is-open')) {
            this.$navigation.addClass('is-scroll');
            this.$mask.addClass('is-fixed');
        }
    }


    toggleItem(t) {
        if (t.target.nodeName === 'A') {
            // aタグを押した場合スキップ
            return;
        }
        let $t = $(t.currentTarget);
        if ($t.hasClass('is-open')) {
            $t.removeClass('is-open');
            $t.children(this.itemChildName).stop().slideUp();
        } else {
            $t.addClass('is-open');
            $t.children(this.itemChildName).stop().slideDown();
        }
    }


}