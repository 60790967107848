// import './sub/sub';
import BrowserDescision from './modules/_BrowserDecision';
import documentBind, {setDocumentScrollTop} from './modules/_documentBind';
import windowBind, {setWindowSize} from './modules/_windowBind';
import OpenNavigation from './modules/_OpenNavigation';
import HoverHeaderNavigation from './modules/_hoverHeaderNavigation';
import StickyHeader from './modules/_StickyHeader';
import ScrollLink from './modules/_scrollLink';
import LinkAnimation from './modules/_LinkAnimation';


let browserDescision = new BrowserDescision();
browserDescision.init();
// options['browserDecision'] = browserDescision.getClasses();


let linkAnimation = new LinkAnimation();
// linkAnimation.bindEvents();

let openNavigation = new OpenNavigation();
openNavigation.bindEvents();

let hoverHeaderNavigation = new HoverHeaderNavigation();
hoverHeaderNavigation.bindEvents();

let stickyHeader = new StickyHeader();
stickyHeader.bindEvents();


let scrollLink = new ScrollLink();
scrollLink.bindEvents();



windowBind.$target.on('load', ()=>{
  linkAnimation.bindEvents();
  scrollLink.bindEvents();
  openNavigation.setHeight();
});

windowBind.$target.on('resize', ()=>{
  setWindowSize();
  openNavigation.setHeight();
});

documentBind.$target.on('scroll', ()=>{
  setDocumentScrollTop();
  stickyHeader.cheackPosition();
});