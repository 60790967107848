import windowBind,{setWindowSize} from './_windowBind';
import {isSizeSp} from './_checkDeviceSize';

export default class ViewMove {

    constructor() {
        this.videoSize = {
            'width': 1024, //1920, // 1280
            'height': 860 //1080 // 720
        };

        this.video = document.createElement('video');
        this.$canvasWrapper = $('#js-video-wrap');
        this.$canvas = $('#js-video');
        this.canvas = this.$canvas[0];
        this.ctx = this.canvas.getContext("2d");
        this.ctime = 0;
        this.lastTime = 0;
        this.ua = navigator.userAgent;
        this.uaTextIphone = /(iPhone|iPad)/;
        this.uaTextAndroid = /(Android)/;
        this.resizeTimer = false;

        this.bindSetSize();
        this.transitionEnd = 'webkitTransitionEnd oTransitionEnd otransitionend transitionend';

        this.visitedFlag = false;

    }

    /**
     * イベントまとめ
     */
    bindEvents() {

        this.setCooike();

        this.setViewHeight();

        this.video.style.display = 'none';
        document.body.appendChild(this.video);

        // ua iPhone iPad 判定
        if (this.uaTextIphone.test(this.ua)) {
            if (this.visitedFlag) {
                this.StartMovieDelayTime = 0;
            } else {
                this.StartMovieDelayTime = 200;
            }
            this.video.addEventListener('canplay', ()=>{
                this.lastTime = Date.now();
                
                this.setIntervalIos();

                windowBind.$target.on('resize', ()=>{
                    if (this.resizeTimer !== false) {
                        clearTimeout(this.resizeTimer);
                    }
                    this.resizeTimer = setTimeout(()=>{
                        this.bindSetSize();
                    }, this.StartMovieDelayTime);

                });
                windowBind.$target.on('load', ()=>{
                    this.bindSetSize();
                });
                
            }, false);
        } else if (this.uaTextAndroid.test(this.ua)) {
            // Androidの場合再生しない
            return false;
        } else {
            // PCの場合
            if (this.visitedFlag) {
                this.StartMovieDelayTime = 0;
            } else {
                this.StartMovieDelayTime = 1500;
            }
            this.$canvasWrapper.removeClass('is-standby');
            this.video.addEventListener('canplay', ()=>{
                setTimeout(()=>{
                    // if (this.$canvasWrapper.hasClass('is-standby')) {
                    //     //this.$canvasWrapper.removeClass('is-standby').addClass('is-play');
                    // }
                    this.$canvasWrapper.addClass('is-play');
                    this.video.muted = true;
                    this.video.autoplay = true;
                    this.video.play();
                    this.video.loop = true;
                    this.setIntervalOther();
                }, this.StartMovieDelayTime);
            }, false);

            windowBind.$target.on('resize', ()=>{
                clearTimeout(this.resizeTimer);
                this.resizeTimer = setTimeout(()=>{
                    this.bindSetSize();
                },500);

            });
            windowBind.$target.on('load',()=>{
                this.bindSetSize();
            });

        }

        this.video.src = this.$canvasWrapper.attr('data-video');
        this.video.load();

    }

    setCooike() {
        this.visitedFlag = $.cookie('visited');
    }

    /**
     * サイズの設定まとめ
     */
    bindSetSize() {
        this.setCanvasSize();
    }


    setIntervalIos() {
        this.intervalTimer = setInterval(()=>{
            let curTime = Date.now();
            let diff = Date.now() - this.lastTime;

            this.lastTime = curTime;
            this.ctime += diff / 1000;
            this.video.currentTime = this.ctime;

            this.ctx.drawImage(this.video, this.drowSize.sx, this.drowSize.sy, this.drowSize.sw, this.drowSize.sh, this.drowSize.dx, this.drowSize.dy, this.drowSize.dw, this.drowSize.dh);

            if (this.video.duration <= this.video.currentTime) {
                this.ctime = 0;
            }
        }, 1000/20);
    }

    setIntervalOther() {
        this.intervalTimer = setInterval(()=>{
            this.ctx.drawImage(this.video, this.drowSize.sx, this.drowSize.sy, this.drowSize.sw, this.drowSize.sh, this.drowSize.dx, this.drowSize.dy, this.drowSize.dw, this.drowSize.dh);
        }, 1000/30);
    }

    setCanvasSize() {
        let width = Math.ceil(this.$canvas.innerWidth());
        let height = Math.ceil(this.$canvas.innerHeight());
        let scaleH = height / this.videoSize.height;
        let scaleW = width / this.videoSize.width ;


        this.canvasSize = {
            'width': width,
            'height': height,
            'scale': scaleH,
            'marginWidth': ((this.videoSize.width * scaleH) - width) / 2,
            'marginHeight': ((this.videoSize.height * scaleW) - height) / 2
        };
        if (width > this.videoSize.width * scaleH) {
            this.canvasSize.scale = scaleW;
            this.drowSize = {
                'sx': 0,
                'sy': 0,
                'sw': this.videoSize.width,
                'sh': this.videoSize.height,
                'dx': 0,
                'dy': -(this.canvasSize.marginHeight),
                'dw': this.canvasSize.width,
                'dh': this.videoSize.height * this.canvasSize.scale
            }
        } else {
            this.canvasSize.scale = scaleH;
            this.drowSize = {
                'sx': 0,
                'sy': 0,
                'sw': this.videoSize.width,
                'sh': this.videoSize.height,
                'dx': -(this.canvasSize.marginWidth),
                'dy': 0,
                'dw': this.videoSize.width * this.canvasSize.scale,
                'dh': this.canvasSize.height
            }
        }

        this.$canvas[0].width = this.canvasSize.width;
        this.$canvas[0].height = this.canvasSize.height;
    }


    setViewHeight() {
        setWindowSize();
        let canvasSize = windowBind.height;
        if (isSizeSp()) {
            canvasSize = '';
        }
        this.$canvasWrapper.css({
            'height': canvasSize
        });
    }

}