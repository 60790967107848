export default class SlideTarget {
    constructor() {
        this.$target = $('.js-target-button');
    }


    bindEvents(){
        this.$target.on('click', (t)=>{
            this.toggleSlide(t)
        });
    }

    toggleSlide(t) {
        let $t = $(t.currentTarget);
        if ($t.hasClass('is-open')) {
            $t.removeClass('is-open');
            $t.next('.target-lists').stop().slideUp();
        } else {
            $t.addClass('is-open');
            $t.next('.target-lists').stop().slideDown();
        }
    }
}