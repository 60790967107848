const $document = $(document);


let documentBind = {
    $target: $document,
    position: 0,
    scrollStop: false
};


export function stopScroll() {
    documentBind.scrollStop = true;
}


export function startScroll() {
    documentBind.scrollStop = false;
}


export function setDocumentScrollTop() {
    if (!documentBind.scrollStop) {
        documentBind.position = documentBind.$target.scrollTop();
    }
}


export default documentBind;