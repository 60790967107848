import $body from './_$body';
import $hero from './_$hero';

/**
 * ローディングとリンク切り替えアニメーション
 */
export default class LinkAnimation {

    constructor() {
        this.targetIdName = '#js-mask';
        this.loadingClassNameBody = 'is-loading';
        this.standbyClassNameBody = 'is-standby'
        this.showClassNameBody = 'is-show';
        this.showClassName = 'is-show';
        this.showClassNameLogo = 'is-show';
        this.hideClassName = 'is-hide';
        this.standbyClassName = 'is-standby';
        this.$target = $(this.targetIdName);
        this.$a = $('a[href]');
        this.$logo = $('#js-mask-logo');
        this.$topHeadingTitle = $('#js-top-heading-title');
        this.transitionEnd = 'webkitTransitionEnd oTransitionEnd otransitionend transitionend';
        this.animationEnd = 'webkitAnimationEnd oAnimationEnd oanimationend animationend';
        this.oneceFlag = false;
        this.addClassInside();
    }

    bindEvents() {
        this.init();
        this.linkHandle();
    }

    /**
     * 読み込み後のアニメーションクラス付け替え
     */
    init() {

        // トップページのみ処理
        if ($body.hasClass('current-index')) {
            let visitedFlag = $.cookie('visited');
            if ( visitedFlag ) {
                // 初回以外

                // ここから 下層ページのような簡略アニメーションの場合
                // this.$target.removeClass(this.standbyClassName).addClass(this.showClassName);
                // this.showAnimationEndEvents();
                // ここまで 下層ページのような簡略アニメーションの場合


                // ここから テキスト等のアニメーションは維持する場合
                this.setStandbyBody();
                this.setWrapTitle();
                this.animationEndTopHeadingTitle();
                this.$target.removeClass(this.standbyClassName).addClass(this.showClassName);
                this.addClassBodyShowed();
                this.showAnimationEndEvents();
                // ここまで テキスト等のアニメーションは維持する場合


            } else {
                // 初回時のみ
                this.setStandbyBody();
                this.setWrapTitle();
                this.animationEndTopHeadingTitle();
                this.$logo.addClass(this.showClassNameLogo);

                this.$logo.on(this.animationEnd, ()=>{


                    if (this.$logo.hasClass('is-hide')) {

                        this.$target.removeClass(this.standbyClassName).addClass(this.showClassName);
                        this.addClassBodyShowed();
                        this.showAnimationEndEvents();

                    } else {
                        this.$logo.addClass('is-hide');
                    }

                });
            }
            this.setCookieVisited();

        } else {
            // その他のページ トップページ以外
            this.$target.removeClass(this.standbyClassName).addClass(this.showClassName);
            this.showAnimationEndEvents();
            $hero.addClass('is-show');
            // console.log('その他のページ イベント');
            // this.$target.on(this.transitionEnd, ()=>{
            //     console.log('アニメーションエンド: ALL');
            //     // $hero.addClass('is-show');
            // });
        }


    }

    setCookieVisited() {
        $.cookie('visited', true, { expires: 1 });
    }

    setStandbyBody(){

        $body.addClass(this.standbyClassNameBody);

    }

    setWrapTitle() {

        this.$topHeadingTitle;
        let txt = this.$topHeadingTitle.text(),
            html = '<div class="heading-title-en-inner">';
        this.headingTextLength = txt.length;
        for (let i=0; i<this.headingTextLength; i++) {
            html += '<span class="heading-title-en-item heading-title-en-item-'+i+'">' + txt.charAt(i) + '</span>';
        }
        this.$topHeadingTitle.html(html);

    }

    animationEndTopHeadingTitle() {

        let counter = 0;
        $('.heading-title-en-item').on(this.animationEnd, ()=>{
            counter++;
            if ( counter >= this.headingTextLength) {
                $body.addClass(this.showClassNameBody);
            }

        });

    }

    showAnimationEndEvents() {

        this.$target.on(this.transitionEnd, (e)=>{
            if (this.$target.hasClass(this.showClassName) ) {
                this.removeClassOpen(e);
            }
        });

    }



    /**
     * is-showをつけた後アニメーション後また取る
     */
    removeClassOpen(e) {
        $(e.currentTarget).removeClass(this.showClassName);
    }


    addClassBodyShowed() {
        $body.addClass(this.loadingClassNameBody);
    }


    /**
     * リンクを押されたらの処理
     */
    linkHandle() {

        let targetLocation = location;

        this.$aInside.on('click', (t)=>{
            let $currentTarget = $(t.currentTarget);
            let pass = $currentTarget.attr("href");
            this.$target.addClass(this.hideClassName).on(this.transitionEnd,()=>{
                targetLocation.href = pass;
            });
            if ( $body.hasClass('browser-android442')) {
                targetLocation.href = pass;
            }
            return false;
        });

        this.clearCloseMask();
    }


    addClassInside() {
        this.$a.each((i, t)=>{
            let current = $(t);
            let href = current.attr('href');
            let target = current.attr('target');
            let chkMatch = /^#/;
            let chkMatchTel = /^tel:|^mailto:/;
            if ( target !== '_blank' && !chkMatch.test(href) && !chkMatchTel.test(href) ) {
                current.addClass('js-link-animation-inside');
            }
        });
        this.getDom();
    }

    getDom() {
        this.$aInside = $('.js-link-animation-inside');
    }


    /**
     * ブラウザバック対策
     */
    clearCloseMask(){
        window.onpageshow = (event)=> {
            if (event.persisted) {
                location.reload();
            }
        };
    }

}
