export default class ScrollLink {
    constructor() {
        this.$a = $('a[href^="#"]');
    }


    bindEvents() {
        /**
         * ページ内スクロール
         */
        this.$a.on('click touchend',function() {
            let href= $(this).attr('href');
            let target = $(href == '#' || href == '' ? 'html' : href);
            let position = target.offset().top;

            if(target.attr('data-offset')){
                let offset = parseInt(target.attr('data-offset'));
                $('html,body').animate({scrollTop: (position - offset)}, 500, 'swing');
            }else {
                $('html,body').animate({scrollTop: position}, 500, 'swing');
            }

            return false;
        });
    }
}