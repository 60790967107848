import windowBind,{setWindowSize} from './_windowBind';

const SP_SIZE = 736;

export function isSizeSp() {
    setWindowSize();
    let r = false;
    if (windowBind.width <= SP_SIZE) {
        r = true;
    }
    return r;
}

export function isSizePc(){
    setWindowSize();
    let r = false;
    if (windowBind.width > SP_SIZE) {
        r = true;
    }
    return r;
}