export default class HoverHeaderNavigation {
    constructor() {
        this.$headerNavigationAbout = $('.js-header-navigation-about');
        this.$navigationChildrenAbout = $('#js-navigation-children-about');
    }




    bindEvents() {
        this.$headerNavigationAbout.mouseenter(()=>{
            this.open();
        }).mouseleave(()=>{
            this.close();
        });
    }

    open() {
        this.$navigationChildrenAbout.addClass('is-open');
        this.$headerNavigationAbout.eq(0).addClass('is-hover');
    }


    close() {
        this.$navigationChildrenAbout.removeClass('is-open');
        this.$headerNavigationAbout.eq(0).removeClass('is-hover');
    }
}