const $window = $(window);


let windowBind = {
    $target: $window,
    width: $window.innerWidth(),
    height: $window.innerHeight()
};


export function setWindowSize() {
    windowBind.width = $window.innerWidth();
    windowBind.height = $window.innerHeight();
}


export default windowBind;