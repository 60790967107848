export default class ScrollAnimation {

    constructor() {
        this.controller = new ScrollMagic.Controller();
    }

    setClassAction(config) {
        if(config.length <= 0){
            return;
        }

        for(let i = 0; i < config.length; i++ ) {
            this.scene(config[i]);
        }
    }

    scene(item) {
        new ScrollMagic.Scene({
            triggerElement: item.trigger,
            reverse: item.reverse,
            triggerHook: item.triggerHook //onEnter, onLeave, onCenter, 0.1~1.0

        })
            .setClassToggle(item.target, 'is-scroll-active')
            .addTo(this.controller);
    }
}