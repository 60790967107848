import documentBind from './_documentBind';

export default class StickyHeader {
    constructor() {
        this.$header = $('#js-header');
        this.switchPoint = 0;
    }


    bindEvents() {
        this.getSize();
    }

    cheackPosition() {
        if (documentBind.position > this.switchPoint) {
            this.$header.addClass('is-sticky');
        } else {
            this.$header.removeClass('is-sticky');
        }
    }


    getSize() {
        this.switchPoint = this.$header.innerHeight() + 100;
    }
}