import windowBind from './modules/_windowBind';
import ViewMove from './modules/_ViewMove';
import SlideTarget from './modules/_SlideTarget';
import ScrollAnimation from './modules/_ScrollAnimation';

import '../sass/style.scss';

let viewMove = new ViewMove();
viewMove.bindEvents();


let slideTarget = new SlideTarget();
slideTarget.bindEvents();

let scrollAnimation = new ScrollAnimation();
let scrollAnimationConfig = [
    {
        trigger: '#js-scroll-trigger-0',
        target: '#js-scroll-target-0',
        triggerHook: 0.8,
        reverse: true
    },
    {
        trigger: '#js-scroll-trigger-1',
        target: '#js-scroll-target-1',
        triggerHook: 0.7,
        reverse: false
    },
    {
        trigger: '#js-scroll-trigger-2',
        target: '#js-scroll-target-2',
        triggerHook: 0.7,
        reverse: false
    },
    {
        trigger: '#js-scroll-trigger-3',
        target: '#js-scroll-target-3',
        triggerHook: 0.7,
        reverse: false
    },
    {
        trigger: '#js-scroll-trigger-3-2',
        target: '#js-scroll-target-3-2',
        triggerHook: 0.7,
        reverse: false
    },
    {
        trigger: '#js-scroll-trigger-4',
        target: '#js-scroll-target-4',
        triggerHook: 0.7,
        reverse: false
    },
    {
        trigger: '#js-scroll-trigger-bg-1',
        target: '#js-scroll-target-bg-1',
        triggerHook: 0.6
    }
];


windowBind.$target.on('load',()=>{
    scrollAnimation.setClassAction(scrollAnimationConfig);
});

windowBind.$target.on('resize', ()=>{
    viewMove.setViewHeight();
});
